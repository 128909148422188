// eslint-disable-next-line no-restricted-imports
import {fire, on} from 'delegated-events'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
import {requestSubmit} from '@github-ui/form-utils'
import {hasFile} from '../behaviors/document-dropzone'

on('click', '.js-bulk-sponsorship-import-csv-button', function () {
  const fileInput = document.getElementById('bulk-sponsorship-import-csv')
  if (!fileInput) return
  ;(fileInput as HTMLInputElement).value = ''
  fileInput.click()
})

on('change', '.js-bulk-sponsorship-import-csv', function (event) {
  const input = event.target as HTMLInputElement
  const form = input.form!
  requestSubmit(form)
})

on('document:drop', '.js-bulk-sponsorship-import-dropzone', async function (event) {
  const fileInput = document.querySelector<HTMLInputElement>('.js-bulk-sponsorship-import-csv')
  if (!fileInput) return

  const transfer = event.detail.transfer as DataTransfer
  fileInput.files = transfer.files

  fire(fileInput, 'change')
})

let dragging: number | null = null

function onBulkSponsorshipDropzoneDragEnter(event: DragEvent) {
  const currentTarget = event.currentTarget as Element
  if (dragging) {
    window.clearTimeout(dragging)
  }
  dragging = window.setTimeout(() => currentTarget.classList.remove('bulk-sponsor-dragover'), 200)

  const transfer = event.dataTransfer
  if (!transfer || !hasFile(transfer)) return

  transfer.dropEffect = 'copy'
  currentTarget.classList.add('bulk-sponsor-dragover')

  event.stopPropagation()
  event.preventDefault()
}

function onBulkSponsorshipDropzoneDragLeave(event: DragEvent) {
  // Ignore leave events from children to prevent flicker.
  if (!(event.target instanceof Element)) return

  if (event.target.classList.contains('js-bulk-sponsorship-import-dropzone')) {
    const currentTarget = event.currentTarget as Element
    currentTarget.classList.remove('bulk-sponsor-dragover')
  }
}

observe('.js-bulk-sponsorship-import-dropzone', {
  constructor: HTMLElement,
  add(el) {
    el.addEventListener('dragenter', onBulkSponsorshipDropzoneDragEnter)
    el.addEventListener('dragover', onBulkSponsorshipDropzoneDragEnter)
    el.addEventListener('dragleave', onBulkSponsorshipDropzoneDragLeave)
  },
  remove(el) {
    el.removeEventListener('dragenter', onBulkSponsorshipDropzoneDragEnter)
    el.removeEventListener('dragover', onBulkSponsorshipDropzoneDragEnter)
    el.removeEventListener('dragleave', onBulkSponsorshipDropzoneDragLeave)
  },
})
