import {fetchSafeDocumentFragment} from '@github-ui/fetch-utils'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

on('click', '.js-show-sponsors-tax-identifier-inputs', () => {
  taxIdentifierInputsWrapper().classList.remove('has-removed-contents')

  const editBtn = document.querySelector<HTMLElement>('.js-edit-sponsors-tax-identifier-edit-btn')
  if (editBtn) editBtn.remove()

  const taxDetails = document.querySelector<HTMLElement>('.js-sponsors-tax-identifier-details')
  if (taxDetails) taxDetails.remove()

  setNonBusinessTaxNoticeVisibility(false)
})

on('click', '.js-hide-sponsors-tax-identifier-inputs', () => {
  taxIdentifierInputsWrapper().classList.add('has-removed-contents')
  setNonBusinessTaxNoticeVisibility(true)
})

function taxIdentifierInputsWrapper() {
  return document.querySelector<HTMLElement>('.js-sponsors-tax-identifier-inputs-wrapper')!
}

function setNonBusinessTaxNoticeVisibility(visibility: boolean) {
  const nonBusinessTaxNotice = document.querySelector<HTMLElement>('.js-non-business-tax-notice')
  if (nonBusinessTaxNotice) nonBusinessTaxNotice.hidden = !visibility
}

on('change', '.js-sponsors-tax-identifier-country-select', async function (event) {
  const countrySelect = event.target as HTMLSelectElement
  updateRegionSelectOptions(countrySelect)
  updateVATVisibility(countrySelect)
})

async function updateRegionSelectOptions(countrySelect: HTMLSelectElement) {
  const countryCode = countrySelect.value
  const regionSelect = document.querySelector('.js-sponsors-tax-identifier-region-select') as HTMLSelectElement

  if (countryCode) {
    const regionsURL = new URL(countrySelect.getAttribute('data-sponsors-iso3166-path')!, window.location.origin)
    regionsURL.search = new URLSearchParams({country_code: countryCode}).toString()
    const regionOptionsHTML = await fetchSafeDocumentFragment(document, regionsURL.toString())

    // It would be nicer to use replaceChildren but it's too recent and still unsupported in TypeScript.
    regionSelect.textContent = ''
    regionSelect.appendChild(regionOptionsHTML)
  } else {
    regionSelect.textContent = ''
  }
}

function updateVATVisibility(countrySelect: HTMLSelectElement) {
  const hideVATCountyCode = 'US'
  const vatCodeWrapper = document.querySelector<HTMLElement>('.js-sponsors-tax-identifier-vat-code-wrapper')!
  const vatCodeInput = document.querySelector<HTMLInputElement>('input.js-sponsors-tax-identifier-vat-code-input')!

  if (countrySelect.value === hideVATCountyCode) {
    vatCodeWrapper.hidden = true
    vatCodeInput.disabled = true
  } else {
    vatCodeWrapper.hidden = false
    vatCodeInput.disabled = false
  }
}
